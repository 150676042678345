import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {Header} from 'semantic-ui-react';
import {PapSidebar} from './components/pap/PapSidebar/PapSidebar';
import {routes} from './routes';

const AddContract = loadable(() => import('./components/contract/AddContract/AddContract'), {resolveComponent: c => c.AddContract});
const AddSubContract = loadable(() => import('./components/contract/AddSubContract'), {resolveComponent: c => c.AddSubContract});
const ViewContract = loadable(() => import('./components/contract/ViewContract/ViewContract'), {resolveComponent: c => c.ViewContract});
const ContractSidebar = loadable(() => import('./components/contract/ContractSidebar/ContractSidebar'), {resolveComponent: c => c.ContractSidebar});
const ContractTable = loadable(() => import('./components/contract/ContractTable'));
const ContractTemplateAddForm = loadable(() => import('./components/contractTemplate/ContractTemplateAddForm'));
const ContractTemplateEditForm = loadable(() => import('./components/contractTemplate/ContractTemplateEditForm'));
const ContractTemplateTable = loadable(() => import('./components/contractTemplate/ContractTemplateTable'));
const InvoiceAddForm = loadable(() => import('./components/InvoiceAddForm'));
const InvoiceDetailsView = loadable(() => import('./components/InvoiceDetailsView'));
const AddPayment = loadable(() => import('./components/payment/AddPayment/AddPayment'), {resolveComponent: c => c.AddPayment});
const PaymentDetailsView = loadable(() => import('./components/payment/view/PaymentDetailsView'), {resolveComponent: c => c.PaymentDetailsView});
const PaymentSidebar = loadable(() => import('./components/payment/view/PaymentSidebar'), {resolveComponent: c => c.PaymentSidebar});
const Transactions = loadable(() => import('./components/transactions/Transactions'), {resolveComponent: c => c.Transactions});
const CaftRelease = loadable(() => import('./components/caftRelease/CaftRelease'), {resolveComponent: c => c.CaftRelease});
const CaftReleaseSidebar = loadable(() => import('./components/caftRelease/CaftReleaseSidebar'), {resolveComponent: c => c.CaftReleaseSidebar});
const CaftReleases = loadable(() => import('./components/caftReleases/CaftReleases'), {resolveComponent: c => c.CaftReleases});
const CaftReleasesSidebar = loadable(() => import('./components/caftReleases/CaftReleasesSidebar'), {resolveComponent: c => c.CaftReleasesSidebar});
const TransactionsSidebar = loadable(() => import('./components/transactions/TransactionsSidebar'), {resolveComponent: c => c.TransactionsSidebar});

const CaftOptions = loadable(() => import('./components/caftOptions/CaftOptions'), {resolveComponent: c => c.CaftOptions});
const PaymentScheduleSidebar = loadable(() => import('./components/PaymentScheduleSidebar/PaymentScheduleSidebar'), {
	resolveComponent: c => c.PaymentScheduleSidebar,
});
const TransactionsHeader = loadable(() => import('./components/transactions/TransactionsHeader'), {resolveComponent: c => c.TransactionsHeader});
const AddPap = loadable(() => import('./components/pap/AddPap/AddPap'), {resolveComponent: c => c.AddPap});
const EditPap = loadable(() => import('./components/pap/EditPap/EditPap'), {resolveComponent: c => c.EditPap});

export const routeProps = createPages(routes, {
	contractTemplates: {
		header: 'Contract Templates',
		content: () => <ContractTemplateTable />,
		sidebar: [
			{
				to: routes.to.contractTemplateAdd(),
				color: 'green',
				text: 'Add Contract Template',
			},
		],
	},
	contractTemplateAdd: {
		header: 'Add Contract Template',
		content: () => <ContractTemplateAddForm />,
	},
	contractTemplateEdit: {
		header: 'Edit Contract Template',
		content: () => <ContractTemplateEditForm />,
	},
	invoiceAdd: {
		header: 'Add Invoice',
		content: () => <InvoiceAddForm />,
	},
	paymentAdd: {
		header: 'Add Payment',
		content: ({params: {accountInfoId}}) => <AddPayment accountInfoId={accountInfoId} />,
	},
	transactions: {
		header: () => <TransactionsHeader />,
		content: () => <Transactions />,
		sidebar: [{render: () => <TransactionsSidebar />}],
	},
	paymentDetails: {
		header: 'Payment',
		content: () => <PaymentDetailsView />,
		sidebar: [{render: () => <PaymentSidebar />}],
	},
	invoiceDetails: {
		header: 'Invoice',
		content: () => <InvoiceDetailsView />,
	},
	contracts: {
		header: 'Contracts',
		content: () => <ContractTable />,
		sidebar: [
			{
				render: () => <Header as="h4">Actions</Header>,
			},
			{
				to: dat => routes.to.contractAdd({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'Add Contract',
			},
			{
				text: 'divider',
				divider: true,
			},
			{
				render: ({params: {accountInfoId}}) => {
					return <PapSidebar accountInfoId={accountInfoId} />;
				},
			},
		],
	},
	contractAdd: {
		header: 'Add Contract',
		content: () => <AddContract />,
	},
	contractEdit: {
		header: 'View Contract',
		content: () => <ViewContract />,
		sidebar: [{render: () => <ContractSidebar />}],
	},
	subContractAdd: {
		header: 'Add Sub Contract',
		content: () => <AddSubContract />,
	},
	caftRelease: {
		header: 'CAFT Release',
		content: () => <CaftRelease />,
		sidebar: [
			{
				render: () => <CaftReleaseSidebar />,
			},
		],
	},
	caftReleases: {
		header: 'CAFT Releases',
		content: () => <CaftReleases />,
		sidebar: [
			{
				render: () => <CaftReleasesSidebar />,
			},
		],
	},
	caftOptions: {
		header: 'CAFT Options',
		content: () => <CaftOptions />,
	},
	papAdd: {
		header: 'Add Preauthorized Payment',
		content: ({params: {accountInfoId}}) => <AddPap accountInfoId={accountInfoId} />,
	},
	papEdit: {
		header: 'Edit Preauthorized Payment',
		content: ({params: {accountInfoId}}) => <EditPap accountInfoId={accountInfoId} />,
	},
});
