import {LocalDatePicker, useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {useAccountingState} from '../../../../accounting/state';
import type {routes} from '../../../routes';
import {useAccountState} from '../../../state';
import {useEditIncOrStartDateMutation} from './editIncOrStartDate';
import {useGetEditIncOrStartDateLazyQuery} from './getEditIncOrStartDate';

const d = debug('tacs.web.accounts.components.accounts.EditIncorporationDate.EditIncOrStartDate');

export function EditIncOrStartDate({type}: {type: 'corporate' | 'self-employed'}) {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {historicClosingDate} = useAccountingState();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [editMutation, {loading: mutationLoading}] = useEditIncOrStartDateMutation();
	const [getQuery, {data, loading, error, called}] = useGetEditIncOrStartDateLazyQuery({variables: {accountInfoId}});
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {date: data?.getIncOrStartDate},
		onSubmit: formValues => {
			editMutation({
				variables: {
					accountInfoId,
					date: formValues.date,
				},
				refetchQueries: ['getAccountState', type === 'corporate' ? 'getCorporateAccountInformation' : 'getSelfEmployedAccountInfoInformation'],
			})
				.then(() => {
					toast.success(type === 'corporate' ? 'Incorporation date saved.' : 'Start date saved.');
					setEditClicked(false);
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		if (!loading && !error && !data?.getIncOrStartDate && !called) {
			getQuery().catch(throwError);
		}
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus label={type === 'corporate' ? 'Incorporation Date' : 'Start Date'}>
					<LocalDatePicker fluid value={values.date || ''} onChange={val => setFieldValue('date', val)} maxDate={historicClosingDate?.plusDays(1)} />
				</FormInput>
				<Button disabled={!values.date} positive type="submit" floated="right">
					Save
				</Button>
				<Button
					disabled={mutationLoading || locked}
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="blue" disabled={locked} onClick={() => setEditClicked(true)}>
			Edit {type === 'corporate' ? 'Incorporation' : 'Start'} Date
		</Button>
	);
}
