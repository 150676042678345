import type {DataHookParams} from '@imperium/layout';
import {LocalDate} from '@js-joda/core';
import {toLocalDate} from '@thx/date';
import debug from 'debug';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useGetFiscalDatePickerMinDateQuery} from '~common/components/FiscalDatePicker/getFiscalDatePickerMinDate';
import {setFiscalOpenDate, setHistoricClosingDate} from '../../accounting/state';
import type {routes} from '../routes';
import {setAccount} from '../state';
import {useGetAccountStateQuery} from './getAccountState';

const d = debug('tacs.web.accounts.hooks.useGetAccount');

interface GetAccount extends DataHookParams {
	routeParams?: typeof routes.types.account;
}

export function useGetAccount({isMatching, routeParams}: GetAccount) {
	const dispatch = useDispatch();
	d(`Rendering useGetAccount hook: ${routeParams?.accountInfoId}, ${isMatching}`);

	useGetAccountStateQuery({
		skip: !isMatching,
		variables: {
			accountInfoId: routeParams?.accountInfoId || '',
		},
		onCompleted: ({getAccountInfoById, getChartOfAccountsByAccountInfoId, getYearEndByAccountInfoId, getIncOrStartDate, getAllFiscalYearRanges}) => {
			d(`Query completed: ${getAccountInfoById?.friendlyId}`);

			if (getAccountInfoById?.id && getAccountInfoById) {
				const yearEnd = getYearEndByAccountInfoId || LocalDate.now().withMonth(12).withDayOfMonth(31);
				dispatch(
					setAccount({
						accountInfoId: getAccountInfoById.id,
						name: getAccountInfoById.name,
						friendlyId: getAccountInfoById.friendlyId,
						type: getAccountInfoById.type,
						yearEnd,
						incorporationDate: getIncOrStartDate || null,
						fiscalYearRanges: getAllFiscalYearRanges || null,
						locked: !getAccountInfoById.contracts?.find(c => c.authorized && c.endDate == null),
					}),
				);
				dispatch(setHistoricClosingDate(getChartOfAccountsByAccountInfoId?.historicClosingDate || null));
			}
		},
	});

	useGetFiscalDatePickerMinDateQuery({
		skip: !isMatching,
		variables: {
			accountInfoId: routeParams?.accountInfoId || '',
		},
		onCompleted: res => {
			if (res.getLastClosedDate) {
				dispatch(setFiscalOpenDate(toLocalDate(res.getLastClosedDate).plusDays(1)));
			} else {
				dispatch(setFiscalOpenDate(null));
			}
		},
	});

	useEffect(() => {
		if (!isMatching) {
			d('Setting no account');
			dispatch(setAccount(null));
			dispatch(setHistoricClosingDate(null));
			dispatch(setFiscalOpenDate(null));
		}
	}, [dispatch, isMatching]);
}
