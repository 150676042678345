import {useCan} from '@imperium/auth-client';
import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {dateRangeSelectionHook} from '~common/hooks/dateRangeSelection/dateRangeSelectionHook';
import {DateNavigatingInterval, DateRangeSelection, DefaultDateSetting} from '~common/types';
import {Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {routes} from './routes';

const d = debug('tacs.web.receivables.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount],
	dataHooks: [
		dateRangeSelectionHook({
			routeMatch: routes.match.transactions,
			selection: DateRangeSelection.DateRange,
			allowFuture: true,
			navigatingInterval: DateNavigatingInterval.Quarter,
			defaultDateSetting: DefaultDateSetting.LastQuarter,
		}),
		dateRangeSelectionHook({
			routeMatch: route => {
				return (
					routes.match.invoiceAdd(route) ||
					routes.match.paymentAdd(route) ||
					routes.match.paymentDetails(route) ||
					routes.match.invoiceDetails(route) ||
					routes.match.contracts(route) ||
					routes.match.papAdd(route) ||
					routes.match.papEdit(route)
				);
			},
			selection: DateRangeSelection.None,
		}),
	],
	secondaryMenu: [
		{
			text: 'Receivables',
			weight: 6,
			stateSelectorHook: [useAccountState, useLayoutState],
			visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]), ...needsRoute('account')},
			permissionSelectorHook: [
				data => {
					const [viewAdminPayrollAccount] = useCan([
						{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
					]);
					return {viewAdminPayrollAccount};
				},
			],
			dropdown: [
				{
					text: 'Contracts',
					icon: 'signup',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.contracts({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					text: 'Transactions',
					icon: 'unordered list',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.transactions({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
			],
		},
		{
			text: 'CAFT Releases',
			to: routes.to.caftReleases,
			visible: {...isLoggedIn(), ...needsRoute('management'), ...needsPermission([Permission.ManageAllAgents])},
		},
		{
			text: 'CAFT Options',
			to: routes.to.caftOptions,
			visible: {...needsPermission(Permission.Sysadmin), ...needsRoute('tools')},
		},
	],
};
