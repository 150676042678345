import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditIncOrStartDate.graphql';

const defaultOptions = {} as const;
export type GetEditIncOrStartDateQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEditIncOrStartDateQuery = {getIncOrStartDate?: LocalDate | null};

export type GetEditIncOrStartDateType = LocalDate;

/**
 * __useGetEditIncOrStartDateQuery__
 *
 * To run a query within a React component, call `useGetEditIncOrStartDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditIncOrStartDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditIncOrStartDateQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEditIncOrStartDateQuery(baseOptions: Apollo.QueryHookOptions<GetEditIncOrStartDateQuery, GetEditIncOrStartDateQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditIncOrStartDateQuery, GetEditIncOrStartDateQueryVariables>(Operations, options);
}
export function useGetEditIncOrStartDateLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEditIncOrStartDateQuery, GetEditIncOrStartDateQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditIncOrStartDateQuery, GetEditIncOrStartDateQueryVariables>(Operations, options);
}
export type GetEditIncOrStartDateQueryHookResult = ReturnType<typeof useGetEditIncOrStartDateQuery>;
export type GetEditIncOrStartDateLazyQueryHookResult = ReturnType<typeof useGetEditIncOrStartDateLazyQuery>;
export type GetEditIncOrStartDateQueryResult = Apollo.QueryResult<GetEditIncOrStartDateQuery, GetEditIncOrStartDateQueryVariables>;
