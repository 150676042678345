import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAccountState.graphql';

const defaultOptions = {} as const;
export type GetAccountStateQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetAccountStateQuery = {
	getYearEndByAccountInfoId?: LocalDate | null;
	getIncOrStartDate?: LocalDate | null;
	getAccountInfoById?: {
		id: string;
		version: number;
		friendlyId: number;
		name: string;
		type: Types.AccountTypeEnum;
		contracts?: Array<{id: string; name: string; authorized: boolean; endDate?: LocalDate | null}> | null;
	} | null;
	getChartOfAccountsByAccountInfoId?: {id: string; historicClosingDate?: LocalDate | null} | null;
	getAllFiscalYearRanges?: Array<{startDate: LocalDate; endDate: LocalDate}> | null;
};

export type GetAccountStateType = LocalDate;

/**
 * __useGetAccountStateQuery__
 *
 * To run a query within a React component, call `useGetAccountStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountStateQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetAccountStateQuery(baseOptions: Apollo.QueryHookOptions<GetAccountStateQuery, GetAccountStateQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAccountStateQuery, GetAccountStateQueryVariables>(Operations, options);
}
export function useGetAccountStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountStateQuery, GetAccountStateQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAccountStateQuery, GetAccountStateQueryVariables>(Operations, options);
}
export type GetAccountStateQueryHookResult = ReturnType<typeof useGetAccountStateQuery>;
export type GetAccountStateLazyQueryHookResult = ReturnType<typeof useGetAccountStateLazyQuery>;
export type GetAccountStateQueryResult = Apollo.QueryResult<GetAccountStateQuery, GetAccountStateQueryVariables>;
